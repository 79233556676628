export const displayNumber = (num, places=2, minPlaces = places, zero, tail) => {
    if (num === 0 && zero)
        return zero;
    let s = (num + 0).toLocaleString("en-US", {
        minimumFractionDigits: minPlaces,
        maximumFractionDigits: places
    });
    if (tail) s += tail;
    return s;
};

export const compactNumber = v => {
    const va = Math.abs(v);
    if (va < 10)
        return displayNumber(v,2, 0);
    if (va < 100)
        return displayNumber(v,1, 0);
    else if (va < 1_000)
        return displayNumber(v, 0);
    else if (va < 1_000_000)
        return displayNumber(v/1000,0) + 'k';
    else if (va < 100_000_000)
        return displayNumber(v/1000000,1, 0) + 'M';
    else if (va < 1_000_000_000)
        return displayNumber(v/1_000_000,0) + 'M';
    else
        return displayNumber(v/1_000_000_000, 1, 0) + 'B';
};

export const ellipsis = (s, n) => {
    if (!s)
        return "";
    else if (s.length > n)
        return s.substring(0,n) + "..."
    else
        return s
};

export const num = (n, singular, plural = singular) => {
    if (n === 0)
        return "no " + plural;
    else if (Math.round(n) === 1)
        return "1 " + singular;
    else return displayNumber(n,0) + " " + plural;
};

export const smartRatio = (a,b) => b === 0 ? 0 : a/b;


export const numberComparator = (a, b) => a-b;

export const stringComparator = (a, b) => a < b ? -1 : (a === b ? 0 : 1);

export const conj = (o, k, v) => {
    const a = o[k];
    if (a !== undefined)
        a.push(v)
    else
        o[k] = [v];
};

export const adjust = (o, k, c) => {
    o[k] = (o[k] || 0) + c;
};

// modulo operator; adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Remainder
export const modulo = (a, n) => ((a % n) + n) % n;

export const classes = (...cs) => cs.filter(x => x).join(" ")

export const debug = x => { console.log(x); return x }

// objects

export const update = sig => sig.value = {...sig.value}

export const isEmptyObject = obj => {
    if (!obj) return true
    // noinspection LoopStatementThatDoesntLoopJS
    for (const name in obj) return false;
    return true;
};

export const uniqueBy = (a, fn) => {
    const seen = new Set();
    return a.filter(v => {
        const x = fn(v);
        if (seen.has(x))
            return false;
        else {
            seen.add(x);
            return true;
        }
    })
}

// vh fix on mobile
import vhCheck from 'vh-check'
vhCheck()

// debounce
// from: https://www.joshwcomeau.com/snippets/javascript/debounce/

export const debounce = (callback, waitMs) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(null, args);
    }, waitMs);
  };
}

// command key modifier: "cmd" for MacOS

export const keyMod = navigator.userAgentData?.platform === "macOS" ? "cmd" : "ctrl"

