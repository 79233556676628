// local storage

const prefix = "youinsight-"

export const writeStore = (key, value) => {
    //console.log("write: " + key + ": " + value)
    const key1 = prefix + key;
    if (value !== undefined)
        window.localStorage.setItem(key1, JSON.stringify(value))
    else
        window.localStorage.removeItem(key1);
    return value
};

export const readStore = (key, def) => {
    const value = window.localStorage.getItem(prefix + key);
    if (value) {
        try {
            //console.log("read: " + key + ": " + value)
            return JSON.parse(value)
        }
        catch (_e) {
            console.log("error while parsing retrieved value for key " + key + " from local storage", _e)
            return def;
        }
    }
    else
        return def;
};
