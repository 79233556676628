// web sockets

import ReconnectingWebSocket from "reconnecting-websocket";
import {signal} from "@preact/signals";

const webSocketUrl = s => {
    const url = new URL(s, window.location.href);
    url.protocol = url.protocol.replace('http', 'ws');
    return url.href;
};

const url = webSocketUrl("/yi/ws")

const urlProvider = () => url

const socket = new ReconnectingWebSocket(urlProvider);

export const socketOpened = signal(false);
export const lastBuildDate = signal(undefined);

const callbacks = new Map()

export function send(message, callbackFn=undefined, timeout = 50_000) {
    if (callbackFn) {
        const id = crypto.randomUUID()
        const content = message[1];
        if (Array.isArray(content))
            content.push(id)
        else
            content.callback = id
        const timeoutId = setTimeout(() => callbacks.delete(id), timeout)
        callbacks.set(id, content => {
            clearTimeout(timeoutId);
            callbackFn(content)
            callbacks.delete(id)
        });
    }
    socket.send(JSON.stringify(message));
    if (import.meta.env.DEV)
        console.log(message)
}

const messageHandlers = new Map();
export const setMessageHandler = (type, handler = _c => {}) => messageHandlers.set(type, handler);


// adapted from https://stackoverflow.com/questions/260857/changing-website-favicon-dynamically
const changeFavicon = src => {
    const link = document.createElement('link'),
        oldLink = document.getElementById('dynamic-favicon');
    if (oldLink) document.head.removeChild(oldLink);
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = src;
    document.head.appendChild(link);
};

changeFavicon("/yi/img/logo1red.png")

socket.addEventListener("open", () => {
    console.log("web socket opened");
    socketOpened.value = true
    onReopen();
    changeFavicon("/yi/img/ok.png")
});

socket.addEventListener("close", () => {
    socketOpened.value = false;
    changeFavicon("/yi/img/logo1red.png")
});

const handleMessage = (type, content) => {
    const handler = messageHandlers.get(type);
    if (handler)
        handler(content);
    else if (import.meta.env.DEV)
        console.log("unknown socket message type " + type);
};

if (import.meta.env.DEV)
    // allow injecting messages from console for debugging purposes
    window.receive = handleMessage

socket.addEventListener("message", ({data}) => {
    try {
        const [type, content] = JSON.parse(data);
        if (import.meta.env.DEV)
            console.log([type, content]);
        handleMessage(type, content);
    }
    catch (e) {
        console.error(e, "while parsing: " + data)
    }
})

let onReopen = () => {};
export function executeOnSocketConnect(fn) {
    onReopen = fn;
    if (socketOpened.value) fn();
}

const ping = JSON.stringify(["ping"]);
setMessageHandler("pong", () => socket.send(ping))

setMessageHandler("callback", content => {
    const fn = callbacks.get(content.shift());
    fn?.(content);
})

setMessageHandler("version", buildDate => {
    if (!lastBuildDate.value) {
        lastBuildDate.value = buildDate;
    }
    else if (lastBuildDate.value !== buildDate) {
        const params = new URLSearchParams(location.search)
        params.set("version", buildDate)
        location.search = "?" + params.toString()
    }
})