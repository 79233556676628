import {fromMarkdown} from "mdast-util-from-markdown";
import {h} from "preact"
import {directiveFromMarkdown} from "mdast-util-directive";
import {directive} from "micromark-extension-directive";
import {gfm} from "micromark-extension-gfm";
import {gfmFromMarkdown} from "mdast-util-gfm";
import {wikiLinks, wikiLinksFromMarkdown} from "./wikilinks.js";
import {classes} from "./utilities.js"
import {extraPageInfo, getVariableSignal, moveToPage} from "../app/configuration.js";
import {brickRenderer, buttonRenderer, contextRenderer, promptRenderer, systemRenderer} from "../app/ai.jsx";
import {checkboxRenderer, inputFieldRenderer, selectRenderer} from "../app/userforms.jsx";
import {maybeRenderJSON} from "./beautify.jsx";

export const pageAst = page => fromMarkdown(page.content, {
    extensions: [directive(), gfm(), wikiLinks()],
    mdastExtensions: [directiveFromMarkdown(), gfmFromMarkdown(), wikiLinksFromMarkdown()]
});

const unknownNodeRenderer = (_node, cs, key) => <div key={key} style={{ backgroundColor: "pink" }}>{cs}</div>

const unsupportedNodeRenderer = (_node, _cs, key) => <div className="unsupperted" key={key}></div>

// Leaf and Text directives are handled in the same way since their data model is equivalent

const simpleDirectiveRenderers = {
    "input": inputFieldRenderer,
    "checkbox": checkboxRenderer,
    "select": selectRenderer,
}

const handleSimpleDirective = (node, cs, key) => {
    const renderer = simpleDirectiveRenderers[node.name];
    if (renderer)
        return renderer(node, cs, key)
    else
        return unsupportedNodeRenderer(node, cs, key)
}

const containerDirectiveRenderers = {
    "prompt": promptRenderer,
    "context": contextRenderer,
    "system": systemRenderer,
    "button": buttonRenderer,
    "brick": brickRenderer,
}

const handleContainerDirective = (node, cs, key, text) => {
    if (node.children.length > 0) {
        if (node.children[0].data?.directiveLabel) {
            node.label = cs[0]
            node.children.shift()
            cs.shift()
        }
        if (node.children.length > 0)
            node.body = resolveVariables(text.substring(node.children[0].position.start.offset, node.children[node.children.length-1].position.end.offset));
        else
            node.body = ""
    }
    node.renderer = renderFragment
    const renderer = containerDirectiveRenderers[node.name];
    if (renderer)
        return renderer(node, cs, key)
    else
        return unsupportedNodeRenderer(node, cs, key)
}

const wikilinkLeft = <span style={{color: "indianred"}}>{"\uff62"}</span>
const wikilinkRight = <span style={{color: "indianred"}}>{"\uff63"}</span>

const code = (node, cs, key) => {
    if (node.lang === "markdown")
        return <div key={key}>{renderFragment({content: node.value || ""})}</div>
    else
        return (<pre className="md code position-relative" key={key}>
            {node.lang && <div className="muted f90 position-absolute" style={{ right: "2px", top: "4px"}}>{node.lang}</div>}
            {node.value}
        </pre>)
}

const handleParagraph = (node, cs, key, text) => {
    if (node.children.length > 0 && node.children[0].position) {
        const body = resolveVariables(text.substring(node.children[0].position.start.offset, node.children[node.children.length-1].position.end.offset));
        const js = maybeRenderJSON(body)
        if (js !== body)
            cs = [js]
    }
    return <p className="md" key={key}>{cs}</p>
}

const renderers = {
    "root": handleParagraph,

    // common
    "blockquote": (node, cs, key) => <blockquote className="md" key={key}>{cs}</blockquote>,
    "break": (node, _cs, key) => <br className="md" key={key}/>,
    "code": code,
    "definition": undefined,
    "emphasis": (node, cs, key) => <em className="md" key={key}>{cs}</em>,
    "html": unsupportedNodeRenderer,
    "heading": (node, cs, key) => h("h" + node.depth, {key, style: {marginTop: "0.6em"}}, cs),
    "image": (node, _cs, key) => {
        let valid
        try
        {
            valid = new URL(node.url, document.location)
        }
        catch (error)
        { /* invalid url */
        }
        return <img className="md" key={key} src={valid && node.url} alt={node.alt} title={node.title}/>;
    },
    "imageReference": undefined,
    "inlineCode": (node, _cs, key) => <span className="md code-inline" key={key}>{node.value}</span>,
    "link": (node, cs, key) => {
        let valid
        try
        {
            valid = new URL(node.url, document.location)
        }
        catch (error)
        { /* invalid url */
        }
        return (<a key={key} className={classes("md", !valid && "disabled")} href={valid && node.url}
                   target="_blank" referrerpolicy="no-referrer">
            {node.title || cs}
            {valid && valid?.origin !== document.location.origin && <i className="ms-1 bi bi-box-arrow-up-right"/>}
        </a>)
    },
    "linkReference": undefined,
    "list": (node, cs, key) => <ul className="md" key={key}>{cs}</ul>,
    "listItem": (node, cs, key) => <li className="md" key={key}>{cs}</li>,
    "paragraph": handleParagraph,
    "strong": (node, cs, key) => <strong className="md" key={key}>{cs}</strong>,
    "text": (node) => maybeRenderJSON(analyzeVariables(node.value)),
    "thematicBreak": (node, _cs, key) => <hr className="md" key={key}/>,

    // GFM
    "delete": (node, cs, key) => <del className="md" key={key}>{cs}</del>,
    "footnoteDefinition": undefined,
    "footnoteReference": undefined,
    "table": undefined,
    "tableCell": undefined,
    "tableRow": undefined,

    // directives
    "leafDirective": handleSimpleDirective,
    "textDirective": handleSimpleDirective,
    "containerDirective": handleContainerDirective,

    // app-specific
    "wikilink": (node, cs, key) =>
        <span className="md wikilink" key={key}
              onClick={e => moveToPage({id: node.id, name: node.name}, e)}>
            {wikilinkLeft}
            {node.name}
            {wikilinkRight}
        </span>
}

const renderElement = (ast, key, text) => {
    const cs = ast.children?.map((a, i) => renderElement(a, i, text))
    const renderer = renderers[ast.type] || unknownNodeRenderer
    return renderer(ast, cs, key, text)
}

const renderFragment = source => {
    const ast = pageAst(source);
    if (import.meta.env.DEV)
        console.log(ast)
    return renderElement(ast, 0, source.content);
}

export const renderPage = source => {
    Object.assign(extraPageInfo, { contexts: [] })
    return renderFragment(source)
}

const Variable = ({name}) => {
    const s = getVariableSignal(name)
    const content = s.value ?? name;
    if (content?.length > 0)
        return <span className={s.value ? "md variable-value" : "md variable"}>{content}</span>;
    else
        return null;
}

const variableReference = /(\$(?:[a-zA-Z_0-9\-*/]+|\{[^}]+}))/;

const analyzeVariables = text => {
    if (!text.includes("$"))
        return text;

    return text.split(variableReference).map(frag => {
        if (frag.startsWith("${"))
            return <Variable name={frag.substring(2, frag.length-1)}/>
        else if (frag.startsWith("$"))
            return <Variable name={frag.substring(1)}/>
        else
            return frag;
    })
}

const resolveVariables = text => {
    if (!text.includes("$"))
        return text;

    const resolve = name => {
        const s = getVariableSignal(name)
        return s.value || name
    }

    let text0 = ""
    text.split(variableReference).forEach(frag => {
        if (frag.startsWith("${"))
            text0 += resolve(frag.substring(2, frag.length-1))
        else if (frag.startsWith("$"))
            text0 += resolve(frag.substring(1))
        else
            text0 += frag;
    })
    return text0;
}