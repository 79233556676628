import {Fragment, h} from "preact";

const renderJSONAtom = v => {
    if (v === null)
        return <span className="fw-bold">null</span>
    else if (v === undefined)
        return <span className="fw-bold">undefined</span>
    else if (typeof v === "string")
        return v;
    else if (typeof v === "number")
        return v;
    else if (typeof v === "boolean")
        return <span className="fw-bold">{v ? "true" : "false"}</span>
}

const renderJSONArray = (o, name) => {
    return (<Fragment key={name || "root"}>
        {name && <div className="md json-array"><span className="md json-head-array">{name}</span></div>}
        {<div className="md json-entry-array">{Object.entries(o).map(([k, v]) => {
            if (Array.isArray(v))
                return renderJSONArray(v, k)
            else if (typeof v === "object" && v !== null)
                return renderJSON(v, k);
            else
                return <div key={k}>{renderJSONAtom(v)}</div>})}
        </div>}
    </Fragment>)
}

const renderJSON = (o, name) => {
    return (<Fragment key={name || "root"}>
        {name && <div className="md json-object"><span className="md json-head">{name}</span></div>}
        {<div className="md json-entry">{Object.entries(o).map(([k, v]) => {
            if (Array.isArray(v))
                return renderJSONArray(v, k)
            else if (typeof v === "object" && v != null)
                return renderJSON(v, k);
            else
                return <div key={k}><span className="fst-italic me-2">{k}:</span>{renderJSONAtom(v)}</div>})}
        </div>}
    </Fragment>)
}

export const maybeRenderJSON = text => {
    if (text) {
        let r;
        try {
            r = JSON.parse(text.trim())
        }
        catch (e) {
            return text;
        }
        if (r && typeof r == "object") {
            return renderJSON(r)
        }
        else return text;
    }
    else return text;
}
