import {send, setMessageHandler} from "../utilities/sockets.js";
import {batch, signal} from "@preact/signals";
import {readStore, writeStore} from "../utilities/storage.js";

export const currentPage = signal({
    name: "?",
    content: "",
    immutable: true,
    props: {},
    generated: {},
    placeholder: true
})

export const configuration = {
    home: undefined,
}

export const configurationUpdate = signal(0)

export const pageHistory = new Map()

export const starred = readStore("starred", {})

export const runningQueryName = signal(undefined)
export const runningQueryPage = signal(undefined)

export  const listedPages = new Set()

export const extraPageInfo = {
    contexts: [],
}


const updateHistory = (id, name)  => {
    if (listedPages.has(id))
        return;
    pageHistory.delete(id);
    pageHistory.set(id, name)
    while (pageHistory.size > 10)
        pageHistory.delete(pageHistory.keys().next().value)
}

export const moveToPage = (page, e) => {
    if (e?.metaKey || e?.ctrlKey)
        window.open(new URL("#" + page.id, window.location.href), "_blank")
    else
        send(["get-page", page]);
}

export const resetRunningState = () => {
    batch(() => {
        runningQueryName.value = undefined
        runningQueryPage.value = undefined
    })
}

export const deleteCurrentPage = () => {
    const id = currentPage.value.id
    pageHistory.delete(id)
    delete starred[id]
    const pages = [...pageHistory.keys()];
    const newId = pages[pages.length-1] || configuration.home
    send(["get-page", { id: newId }])
    send(["delete-page", id])
    writeStore("starred", starred)
}

setMessageHandler("configuration", content => {
    Object.assign(configuration, content);
    configurationUpdate.value++;
    if (currentPage.value.placeholder) {
        const hash = location.hash
        let id
        if (hash.length === 37)
            id = hash.substring(1)
        else
            //id = readStore("current-page", configuration.home)
            id = configuration.home
        moveToPage({id})
    }
})

const variables = new Map()

setMessageHandler("page", message => {
    if (message.id !== currentPage.value.id) {
        variables.clear()
    }
    resetRunningState()
    if (!message.props)
        message.props = {}
    if (!message.generated)
        message.generated = {}
    currentPage.value = message
    const id = message.id;
    updateHistory(id, message.name)
    document.title = message.name + " \u2014 YourInsight"
    writeStore("current-page", id)
    history.pushState(message, undefined, "#" + id)
})

addEventListener("popstate", e => {
    if (e.state?.id) {
        currentPage.value = e.state
        // need to notify the new current page to the server, even if it is optimistically updated used the version
        // cached in history
        moveToPage(e.state)
    }
})

export const getVariableSignal = (name, def) => {
    const generated = currentPage.value.generated[name]?.text;
    if (generated !== undefined)
        return { value: generated }
    const s = variables.get(name);
    if (s)
        return s;
    const s1 = signal(def)
    variables.set(name, s1)
    return s1;
}

export const getVariables = () => {
    if (variables.size === 0)
        return undefined;
    const r = {}
    for (const [k,v] of variables.entries())
        if (v.value)
            r[k] = v.value;
    return r;
}
