import {render} from "preact";
import {Page} from "./app/page.jsx";
import {useEffect, useRef} from "preact/hooks";
import {focused, keyListener, Results, searchQuery} from "./app/search.jsx";
import {version} from "./version.js";
import {ConnectionStatus} from "./app/elements.jsx";
import {send, setMessageHandler, socketOpened} from "./utilities/sockets.js";
import Tippy from "@tippyjs/react";
import {signal} from "@preact/signals";
import {classes} from "./utilities/utilities.js"

const processing = signal(false)

setMessageHandler("recalc-end", () => {
    processing.value = false;
})

const recalc = () => {
    if (!processing.value) {
        processing.value = true;

        send(["recalc"])
    }
}

addEventListener("keydown", e => { if (e.key === 'F4') recalc() })

const App = () => {
    const searchRef = useRef()

    useEffect(() => searchRef.current.focus())

    return (<div id="main-box" className="container-fluid d-flex flex-column align-items-stretch"
             style={{backgroundColor: "white", padding: "0 4px", height: "auto"}}>

            <div style={{width: "100%", minHeight: "8px", background: "#E3DAC9"}}/>

        <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex user-select-none px-3 border-bottom"
             style={{minHeight: "52px", height: "52px"}}>
                    <span className="navbar-brand mb-0 h1 pointer" style={{fontSize: "21px"}}
                          onClick={() => location.href = "/yi/app.html"}>YourInsight
                        <span className="ms-2 muted small">{version}</span></span>

            <ConnectionStatus color={socketOpened.value ? "limegreen" : "red"}/>

            <Tippy content={processing.value ? "..." : "Recalc"}>
                <i className={classes("bi px-2 icon-button bi-arrow-clockwise ms-3", processing.value && "muted")}
                   onClick={recalc}/>
            </Tippy>
            <span className="flex-grow-1 "/>

            <i className="bi bi-search me-2"/>
            <div className="position-relative">
                <input className="search-field px-2 pt-2 pb-1 border" type="text" value={searchQuery.value}
                       onKeyDown={keyListener}
                       onInput={e => searchQuery.value = e.target.value}
                       onFocus={() => focused.value = true}
                       onBlur={() => setTimeout(() => focused.value = false, 300)}
                       style={{width: "50vw"}} placeholder="search..." ref={searchRef}/>
                <Results style={{width: "50vw", top: "35px"}} maxHeight={500}/>
            </div>
        </nav>
        <Page/>
    </div>)
}

render(<App/>, document.getElementById("app"))