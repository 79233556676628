import {useEffect, useRef} from "preact/hooks";
import {
    drawSelection,
    EditorView,
    highlightActiveLine,
    highlightActiveLineGutter,
    keymap,
    lineNumbers
} from "@codemirror/view";
import {
    bracketMatching,
    codeFolding,
    defaultHighlightStyle,
    foldGutter,
    foldKeymap,
    syntaxHighlighting
} from "@codemirror/language";
import {closeBrackets, closeBracketsKeymap} from "@codemirror/autocomplete";
import {highlightSelectionMatches, searchKeymap} from "@codemirror/search";
import {copyLineDown, defaultKeymap, history, historyKeymap} from "@codemirror/commands";
import {markdown, markdownLanguage} from "@codemirror/lang-markdown";
import {EditorState} from "@codemirror/state";
import {configuration, currentPage, deleteCurrentPage} from "./configuration.js";
import Tippy from "@tippyjs/react";
import {effect, signal} from "@preact/signals";
import {update} from "../utilities/utilities.js";
import {send} from "../utilities/sockets.js";

const extensions = [
    lineNumbers(),
    history(),
    syntaxHighlighting(defaultHighlightStyle),
    markdown({ base: markdownLanguage, codeLanguages: [] }),
    drawSelection(),
    bracketMatching(),
    closeBrackets(),
    codeFolding(),
    foldGutter(),
    highlightActiveLine(),
    highlightActiveLineGutter(),
    highlightSelectionMatches(),
    EditorView.lineWrapping,
    keymap.of([
        { key: "Mod-d", run: copyLineDown },
        ...closeBracketsKeymap,
        ...foldKeymap,
        ...defaultKeymap,
        ...searchKeymap,
        ...historyKeymap,
    ])];

let lastY = 0;

const editorRef = {}

export const editing = signal(false)

export const cancelEditing = () => {
    const p = currentPage.value;
    if (p.original !== undefined) {
        p.content = p.original;
        delete p.original
    }
    if (p.originalName !== undefined) {
        p.name = p.originalName;
        delete p.originalName
    }
    update(currentPage)
    editing.value = false
}

const previewEditing = () => {
    const modified = editorRef.current.state.doc.toString()
    const p = currentPage.value;

    if (modified !== (p.original ?? p.content)) {
        if (p.original === undefined)
            p.original = p.content;
        p.content = modified;
    }
    else
        delete p.original

    if (pageName.value !== (p.originalName ?? p.name)) {
        if (p.originalName === undefined)
            p.originalName = p.name;
        p.name = pageName.value;
    }
    else
        delete p.originalName

    editing.value = false
}

const commmitEditing = () => {
    const modified = editorRef.current.state.doc.toString()
    const p = currentPage.value;

    p.content = modified
    delete p.original;

    p.name = pageName.value;
    delete p.originalName;

    send(["upsert-page", currentPage.value])
    editing.value = false
}

const deleteEditing = () => {
    deleteCurrentPage()
    editing.value = false
}

const pageName = signal("")

effect(() => pageName.value = currentPage.value.name)

export const PageEditorHeader = () => {
    return (
        <div className="sticky-top border-start border-end border-bottom d-flex flex-row align-items-center py-2"
             style={{background: "#f5f5f5", fontSize: "120%"}}>
            <input className="mx-3 px-1 flex-grow-1 no_outline" value={pageName.value} type="text"
                   style={{ background: "white", borderTopLeftRadius: "5px", borderTopRightRadius: "5px"}}
                   onInput={e => pageName.value = e.target.value}/>
            <Tippy content="commit edit">
                <i className="bi bi-check-lg text-success px-2 icon-button" onClick={commmitEditing}/>
            </Tippy>
            <Tippy content="preview">
                <i className="bi bi-eyeglasses px-2 icon-button" onClick={previewEditing}/>
            </Tippy>
            {currentPage.value.id !== configuration.home &&
                <Tippy content="delete">
                    <i className="bi bi-trash3 px-2 me-2 icon-button" style={{ color: "red"}} onClick={deleteEditing}/>
                </Tippy>}
            <Tippy content="cancel edit">
                <i className="bi bi-x-lg text-danger px-2 me-2 icon-button" onClick={cancelEditing}/>
            </Tippy>
        </div>)
}


export const PageEditor = () => {
    const ref = useRef()

    const changeListener = EditorView.updateListener.of(v => {
        if (v.docChanged) {
            // todo
        }
    })

    useEffect(() => {
        editorRef.current = new EditorView({
          state: EditorState.create({ doc: currentPage.value.content, extensions: [...extensions, changeListener]}),
            parent: ref.current
        })
        editorRef.current.setContent = function(text) {
            const update = this.state.update({changes: {from: 0, to: this.state.doc.length, insert: text}});
            this.update([update]);
        }
        setTimeout(() => {
            editorRef.current.scrollDOM.scrollTo(0, lastY);
            editorRef.current.focus()
        }, 0);
        editorRef.current.scrollDOM.addEventListener("scroll", () => {
            lastY = editorRef.current.scrollDOM.scrollTop;
        })
    }, [])


    return (<div ref={ref} className="flex-grow-1 border-start border-end border-bottom"/>)
}

