import {error} from "./elements.jsx";
import {getVariableSignal} from "./configuration.js";
import {Fragment} from "preact";
import Select from "react-select";

export const inputFieldRenderer = (node, cs, key) => {
    const id = node.attributes.id;
    if (!id)
        return error("input field: missing id")
    const s = getVariableSignal(id, node.attributes.value);
    return (<Fragment key={key}>
        {cs}
        <input
            className="form-control md input-text" type="text"
            value={s}
            onInput={e => s.value = e.target.value || null}/>
    </Fragment>)
}

const customSelectStyles = (style, menuStyle = {}) => ({
    option: base => ({
        ...base,
        overflowX: "hidden",
        padding: "4px 8px",
        whiteSpace: "nowrap"
    }),
    control: base => ({
        ...base,
        minHeight: '36px',
        fontSize: "1rem",
    }),
    input: base => ({
        ...base,
        margin: '0px',
    }),
    menu: base => ({...base, zIndex: 3, ...menuStyle}),
    menuPortal: (base) => ({ ...base, zIndex: 999 }),
    container: base => ({
        ...base,
        ...style
    })
})

export const selectRenderer = (node, cs, key) => {
    const id = node.attributes.id;
    if (!id)
        return error("select: missing id");
    const options = [];
    (node.attributes.options ?? "").split("|").forEach(o => options.push({ label: o, value: o }))
    const s = getVariableSignal(id, options.find(o => o.label === node.attributes.value)?.value);
    return <Fragment key={key}>
        {cs}
        <Select className="md select" options={options} defaultValue={options.find(o => o.value === s.value)} styles={customSelectStyles()}
                placeholder={node.attributes.placeholder}
                isClearable={true}
                onChange={v => s.value = v?.value}/>
    </Fragment>
}

export const checkboxRenderer = (node, cs, key) => {
    const id = node.attributes.id;
    if (!id)
        return error("checkbox: missing id")
    const eid = "_check_" + id;
    const s = getVariableSignal(id + "_check", node.attributes.value || false);
    const k = s.value ? "true" : "false"
    const s2 = getVariableSignal(id, node.attributes[k] ?? k);
    return (<div className="form-check md checkbox" key={key}>
        <input className="form-check-input" type="checkbox" value="" checked={s} id={eid}
        onChange={e => {
            s.value = e.target.checked;
            const k = s.value ? "true" : "false"
            s2.value = node.attributes[k] ?? k
        }}/>
        <label className="form-check-label" htmlFor={eid} >
            {cs}
        </label>
    </div>)
}
