import {renderPage} from "../utilities/markdown.jsx";
import {currentPage, listedPages, moveToPage, pageHistory, starred} from "./configuration.js";
import Tippy from "@tippyjs/react";
import {cancelEditing, editing, PageEditor, PageEditorHeader} from "./pageEditor.jsx";
import {classes, update} from "../utilities/utilities.js";
import {writeStore} from "../utilities/storage.js";
import {format} from "date-fns";

const Sidebar = () => {
    // noinspection JSUnusedLocalSymbols
    const _p = currentPage.value;

    const pageEntry = (name, uuid, icon) => {
        listedPages.add(uuid)
        return <div className="pointer" onClick={e => {
            editing.value = false
            moveToPage({id: uuid}, e);
        }}>
            <i className={classes("bi me-2", icon)}/>{name}
        </div>;
    }

    return (<div className="ms-3 mt-1" style={{minWidth: "296px", width: "296px"}}>
        <div className="position-sticky overflow-x-hidden" style={{background: "white", top: "8px"}}>
            {pageEntry("Start Page", "8c425736-7749-4302-9a64-66e3875bd67c", "bi-house-fill")}
            {pageEntry("All Pages", "7754c920-c9b3-448e-95da-0e4cb58ba7f4", "bi-bookmarks")}
            <div className="d-flex flex-column align-items-stretch mt-3">
                {[...pageHistory.entries()].map(([id, name]) =>
                    <div className="pointer mb-1" key={id} onClick={e => {
                        editing.value = false
                        moveToPage({id, name}, e);
                    }}>
                        <i className="bi bi-file-earmark me-2"/>{name}
                    </div>)}
                {pageHistory.size === 0 && <div className="fst-italic muted">No history</div>}
            </div>
            <div className="d-flex flex-column align-items-stretch mt-2">
                {Object.entries(starred).map(([id, name]) =>
                    <div className="pointer mb-1" key={id} onClick={e => {
                        editing.value = false
                        moveToPage({id, name}, e);
                    }}>
                        <i className="bi bi-star-fill me-2" style={{color: "#FFBA00"}}/>{name}
                    </div>)}
            </div>
            <div className="mt-2 mb-3 border-bottom"/>
            {editing.value && <div><i className="bi bi-markdown-fill me-2"/>Page text formatting and structure is based on
                <a className="ms-1" target="_markdown" href="https://commonmark.org/help/">Markdown</a></div>}
        </div>
    </div>)
}

const copyLink = () => {
    const value = currentPage.value;
    // noinspection JSIgnoredPromiseFromCall
    navigator.clipboard.writeText("[[" + value.name + ":" + value.id +  "]]")
}

const PageHeader = () => {
    const p = currentPage.value;
    const star = starred[currentPage.value.id]
    return (
        <div className="sticky-top border-start border-end border-bottom d-flex flex-row align-items-center py-2"
             style={{background: "#fcfcfc", fontSize: "120%"}}>
            <div className="ms-3 flex-grow-1">{p.name}</div>
            {p.unsaved && !p.immutable && <span className="muted small mt-1 me-2 user-select-none">new</span>}
            {p.original !== undefined && <>
                <span className="muted small mt-1 me-2 user-select-none">edited</span>
                <Tippy content="revert">
                    <i className="bi bi-arrow-counterclockwise px-2 icon-button" onClick={cancelEditing}/>
                </Tippy>
            </>}
            <Tippy content="copy link">
                <i className="bi px-2 icon-button bi-link" onClick={copyLink}/>
            </Tippy>
            {!p.placeholder &&
                <i className={classes("bi px-2 icon-button", star ? "bi-star-fill" : "bi-star")}
                   style={{color: star && "#FFBA00"}} onClick={toggleStar}/>}
            {p.immutable ?
                <i className="bi bi-lock-fill px-2 me-2 icon-button muted" style={{cursor: "default"}}/> :
                <Tippy content="edit page">
                    <i className="bi bi-pencil px-2 me-2 icon-button" onClick={() => editing.value = true}/>
                </Tippy>}
        </div>)
}

const PageDisplay = () => {
    return (<div
        className="flex-grow-1 border-start border-end border-bottom p-3 pb-2 d-flex flex-column align-items-stretch">
        {renderPage(currentPage.value)}
        {currentPage.value?.ts > 0 && <>
            <span className="flex-grow-1"/>
            <div className="small muted mt-2">{format(new Date(currentPage.value.ts), "yyyy-M-d H:mm")}</div>
        </>}
    </div>)
}

const toggleStar = () => {
    if (starred[currentPage.value.id])
        delete starred[currentPage.value.id]
    else
        starred[currentPage.value.id] = currentPage.value.name
    update(currentPage)
    writeStore("starred", starred)
}

export const Page = () => {
    return (<div className="d-flex flex-row align-items-stretch mt-3 container-xxl"
                 style={{minHeight: "calc(100vh - 80px)", height: "auto"}}>
        <div className="flex-grow-1 d-flex flex-column align-items-stretch border-top">
            {editing.value ? <PageEditorHeader/> : <PageHeader/>}
            {editing.value ? <PageEditor/> : <PageDisplay/>}
        </div>
        <Sidebar/>
    </div>)
}