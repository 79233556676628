import {effect, signal} from "@preact/signals";
import {send} from "../utilities/sockets.js";
import {FixedSizeList} from "react-window";
import {moveToPage, starred} from "./configuration.js";

export const searchQuery = signal("")

export const focused = signal(false)

const searchResults = signal([])

const selected = signal(undefined)

const action = item => {
    if (item.plus)
        moveToPage({ name: item.title })
    else
        moveToPage({ id: item.id })

}

export const keyListener = event => {
    if (event.code === "Enter") {
        let index;
        if (event.shiftKey) {
            if (searchResults.value[0].plus)
                index = 0;
        }
        else {
            index = selected.value ?? searchResults.value.findIndex(v => v.first)
        }
        if (index >= 0) {
            action(searchResults.value[index])
            event.target.blur()
        }
    }
}

export const Results = ({style, maxHeight}) => {
    if (!focused.value)
        return null;
    if (searchResults.value.length === 0)
        return null;

    const rowHeight = 30;
    const height = Math.min(maxHeight, searchResults.value.length * rowHeight);
    const Result = ({index, style}) => {
        const r = searchResults.value[index]
        const isSelected = selected.value === index || (selected.value === undefined && r.first)
        return <div className="border-bottom px-2 py-1 d-flex flex-row align-items-center pointer"
                    onClick={() => action(r)}
                    style={{...style, background: isSelected ? "#FED" : "white"}}
                    onMouseOver={() => selected.value = index}
                    onMouseOut={() => selected.value = undefined}>
            <span className="mt-1">
                {r.plus && <><i className="bi bi-plus-lg text-success me-2"/><span className="fw-bold me-2">Create:</span></>}
                {r.id && starred[r.id]
                    ? <i className="bi bi-star-fill me-2" style={{color: "#FFBA00"}}/>
                    : <i className="bi bi-file-earmark me-2"/>}
                {r.title}
            </span>
            <span className="flex-grow-1"/>
            {r.plus && <><kbd className="kbd-light me-1">shift</kbd>+<kbd className="ms-1 kbd-light">enter</kbd></>}
            {r.first && <kbd className="kbd-light">enter</kbd>}
        </div>
    }
    return (<div className="position-absolute border" style={{...style, height: height+"px", zIndex: 1030}}>
        <FixedSizeList height={height} itemCount={searchResults.value.length} itemSize={rowHeight} width="100%">
            {Result}
        </FixedSizeList>
    </div>)
}

effect(() => {
    const query = searchQuery.value;
    if (query.length >= 3) {
        send(["query", {query}], ([response]) => {
            if (response.query === query) {
                if (response.results.length > 0)
                    response.results[0].first = true
                searchResults.value = [{plus: true, title: query}, ...(response.results || [])]
            }
        })
    }
    else
        searchResults.value = []
})

