// support for markdown syntax for wiki-style links [[ ... ]]

const openSquareBracket = 0x5B;
const closeSquareBracket = 0x5D;
const escape = 92

const linkTokenize = (effects, ok, nok) => {
    const open1 = code => {
        effects.enter("wikilink");
        effects.enter("wikilinkMarker");
        effects.consume(code);
        return open2;
    }

    const open2 = code => {
        if (code !== openSquareBracket)
            return nok(code);

        effects.consume(code);
        effects.exit("wikilinkMarker")
        effects.enter("wikilinkContent")
        return begin;
    }

    const begin = code => {
        if (code === closeSquareBracket)
            return nok(code)
        else
            return inside(code)
    }

    const inside = code => {
        if (code === -5 || code === -4 || code === -3 || code === null)
            return nok(code)

        if (code === escape) {
            effects.consume(code);
            return insideEscape;
        }

        if (code === closeSquareBracket) {
            effects.exit("wikilinkContent")
            effects.enter("wikilinkMarker")
            effects.consume(code)
            return close2
        }

        effects.consume(code);
        return inside;
    }

    const insideEscape = code => {
        if (code === escape || code === closeSquareBracket) {
            effects.consume(code);
            return inside;
        }
        return inside(code)
    }

    const close2 = code => {
        if (code !== closeSquareBracket)
            return nok(code);

        effects.consume(code);
        effects.exit("wikilinkMarker")
        effects.exit("wikilink")
        return ok;
    }

    return open1
}

const linkConstruct = {
    name: "wikilink",
    tokenize: linkTokenize
}

export const wikiLinks = () => ({ text: { [openSquareBracket]: linkConstruct } })

function wikilinkEnter(token) {
    this.enter({ type: "wikilink", name: "" }, token)
}

function wikilinkExit(token) {
    this.exit(token);
}

function wikilinkContentExit(token) {
    const node = this.stack[this.stack.length - 1]
    //assert(node.type === "wikilink")
    let name = this.sliceSerialize(token)
    // :UUID
    if (name.length > 37 && name.substring(name.length-37).match(/:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i)) {
        node.id = name.substring(name.length-36).toLowerCase();
        name = name.substring(0, name.length-37)
    }
    node.name = name;
}

export const wikiLinksFromMarkdown = () => {
    return {
        enter: {
            wikilink: wikilinkEnter,
        },
        exit: {
            wikilink: wikilinkExit,
            wikilinkContent: wikilinkContentExit,
        }
    }
}