import {send, setMessageHandler} from "../utilities/sockets.js";
import Tippy from "@tippyjs/react";
import {
    currentPage,
    extraPageInfo,
    getVariables,
    resetRunningState,
    runningQueryName,
    runningQueryPage
} from "./configuration.js";
import {batch, signal} from "@preact/signals";
import {classes} from "../utilities/utilities.js"
import {format} from "date-fns";
import {error} from "./elements.jsx";

const updateRunning = signal(0)

const ensure = name => {
    if (!currentPage.value.generated[name])
        currentPage.value.generated[name] = { text: "" }
}

setMessageHandler("token", ([id, fragmentName, token]) => {
    if (currentPage.value.id !== id)
        return;
    batch(() => {
        runningQueryName.value = fragmentName
        runningQueryPage.value = id
    })
    ensure(fragmentName)
    currentPage.value.generated[fragmentName].text += token
    updateRunning.value++;
})

setMessageHandler("prompt-clear", ([id, fragmentName]) => {
    if (currentPage.value.id !== id)
        return;
    ensure(fragmentName)
    currentPage.value.generated[fragmentName] = { text: "" }
    updateRunning.value++;
})

const startQuery = (pageId, queryName, prompt, mode, use, query, model, maxTokens, noCache) => {
    const page = currentPage.value;
    if (page.id !== pageId)
        return;
    page.generated[queryName] = { text: "" }
    send(["prompt-start", {
        name: queryName,
        itemId: pageId,
        prompt,
        mode,
        use,
        query,
        model,
        noCache,
        maxTokens,
        variables : getVariables(),
        contexts: extraPageInfo.contexts
    }])
    batch(() => {
        runningQueryName.value = queryName
        runningQueryPage.value = pageId
    })
    updateRunning.value++;
}

const stopQuery = (pageId, queryName) => {
    send(["prompt-stop", { name: queryName, itemId: pageId } ])
    ensure(queryName)
    currentPage.value.generated[queryName].stopped = true
    resetRunningState()
    updateRunning.value++;
}

const quote = text => text.split("\n").map(t => "> " + t).join("\n")

const jsonToForm = value => {
    if (value === null || value === undefined)
        return "";
    if (Array.isArray(value)) {
        let r = ""
        value.map((v,k) => {
            if (typeof v === "number" || typeof v === "string" || v === null || v === undefined) {
                r += ":input[" + k + "]{id='" + k +"' value='" + v + "'}\n\n"
            }
            else if (typeof v === 'object') {    // includes array
                r += quote(k + "\n\n" + jsonToForm(v))
            }
            else if (typeof v === 'boolean') {
                r += ":checkbox[" + k +"]{id='" + k + " value=" + v + "'}\n\n"
            }
        })
        return r;
    }
    else if (typeof value === 'object') {
        let r = ""
        for (const [k, v] of Object.entries(value)) {
            if (typeof v === "number" || typeof v === "string" || v === null || v === undefined) {
                r += ":input[" + k + "]{id='" + k +"' value='" + v + "'}\n\n"
            }
            else if (typeof v === 'object') {    // includes array
                r += quote("### " + k + "\n\n" + jsonToForm(v))
            }
            else if (typeof v === 'boolean') {
                r += ":checkbox[" + k +"]{id='" + k + " value=" + v + "'}\n\n"
            }
        }
        return r;
    }
    else
        return value.toString();
}

const clearResult = (pageId, queryName) => {
    send(["prompt-clear", { name: queryName, itemId: pageId } ])
}

const changed = (id, color="text-danger") => {
    const ch = currentPage.value.changed?.includes(id)
    if (ch)
        return <i class={classes("bi bi-three-dots ms-1", color)}/>
    else
        return null
}

export const promptRenderer = (node, cs, key) => {
    // noinspection JSUnusedLocalSymbols
    const _z = updateRunning.value
    if (!node.attributes.id)
        return error("prompt: missing id")

    const running = runningQueryName.value === node.attributes.id && currentPage.value.id === runningQueryPage.value;
    const result = currentPage.value.generated[node.attributes.id]
    const mode = node.attributes.mode;
    let resultText;
    if (result) {
        if (mode === "form") {
            resultText = result.text;
            try {
                resultText = jsonToForm(JSON.parse(resultText));
            }
            catch(e) {
                // invalid json, maybe because of partial generation
            }
            resultText = node.renderer({ content: resultText || ""})
        }
        else if (mode === "markdown" || mode === "json" || mode === "json0")
            resultText = node.renderer({ content: result.text || ""})
        else
            resultText = (result.text || "").replaceAll("`", "'")
    }
    return (<div key={key} className="md prompt m-2 mt-3 d-flex flex-column align-items-stretch">
        <ul className="d-flex align-items-center nav nav-tabs">
            {node.attributes.id && <>
                <li className="nav-item">
                    <div className="nav-link active">{node.attributes.id}{changed(node.attributes.id)}</div>
                </li>
                {running ?
                    <i className="bi mx-2 mt-1 bi-x-lg text-danger pointer" style={{fontSize: "120%", width: "23px"}}
                       onClick={() => stopQuery(currentPage.value.id, node.attributes.id)}/> :
                    <i className="bi mx-2 mt-1 bi-play-fill text-success pointer" style={{fontSize: "150%", width: "23px"}}
                       onClick={e =>
                           startQuery(currentPage.value.id, node.attributes.id, node.body, mode, node.attributes.use,
                               node.attributes.query, node.attributes.model, node.attributes.max, e.shiftKey)}/>}
                <Tippy content="Copy result" placement="bottom-start">
                    <i className="bi mx-2 mt-1 bi-copy pointer" style={{fontSize: "120%"}} onClick={() => {
                        if (result?.text)
                            Navigator.clipboard.writeText(result.text).then(() => {})
                    }}/>
                </Tippy>
                {running && <div className="spinner-grow spinner-grow-sm text-danger ms-5 mb-1"/>}
                <span className="flex-grow-1"/>
                {node.attributes.query && <div className="d-flex align-items-center pointer me-2">
                    <i className="bi bi-search me-2" style={{fontSize: "120%"}}/>{node.attributes.query}</div>
                }
                {node.attributes.use &&
                    node.attributes.use.split(",").map(s => <div className="d-flex align-items-center pointer me-2">
                    <i className="bi bi-arrow-up-right me-2" style={{fontSize: "120%"}}/>{s}
                </div>)}
                {node.attributes.auto !== undefined && <div className="mx-2"><Tippy content="auto update" placement="bottom-start">
                    <i className="bi bi-lightning-charge-fill" style={{fontSize: "120%", color: "darkorange"}}/>
                </Tippy></div>}
                {(mode === "json" || mode === "json0") && <div className="mx-2"><Tippy content="produce JSON" placement="bottom-start">
                    <i className="bi bi-braces-asterisk" style={{fontSize: "120%", color: "midnightblue"}}/>
                </Tippy></div>}
                {mode === "form" && <div className="mx-2"><Tippy content="generate a form" placement="bottom-start">
                    <i className="bi bi-ui-checks-grid" style={{fontSize: "120%", color: "midnightblue"}}/>
                </Tippy></div>}
                {mode === "markdown" && <div className="mx-2"><Tippy content="produce markdown" placement="bottom-start">
                    <i className="bi bi-markdown-fill" style={{fontSize: "120%", color: "midnightblue"}}/>
                </Tippy></div>}
            </>}
        </ul>
        <div className={classes("p-3 pb-1 border-start border-end prompt-body", !resultText && "border-bottom")}>
            {cs}
        </div>
        {resultText && <pre className="p-3 pb-2 border answer-body">
            {resultText}
            {result.date && <div className="muted small mt-1">
                {format(new Date(result.date), "yyyy-M-d H:mm")}
                <span className="ms-3 flex-grow-1">{result.meta || ""}</span>
                <i className="ms-3 pointer bi bi-x-lg text-danger" onClick={() => clearResult(currentPage.value.id, node.attributes.id)}/>
            </div>}
        </pre>}
    </div>)
}

export const buttonRenderer = (node, cs, key) => {
    // noinspection JSUnusedLocalSymbols
    const _z = updateRunning.value
    if (!node.attributes.id)
        return error("button: missing id")

    // noinspection JSUnusedLocalSymbols
    const running = runningQueryName.value === node.attributes.id && currentPage.value.id === runningQueryPage.value;
    const result = currentPage.value.generated[node.attributes.id]
    const mode = node.attributes.mode;
    let resultText;
    if (result) {
        if (mode === "form") {
            resultText = result.text;
            try {
                resultText = jsonToForm(JSON.parse(resultText));
            }
            catch(e) {
                // invalid json, maybe because of partial generation
            }
            resultText = node.renderer({ content: resultText || ""})
        }
        else if (mode === "markdown" || mode === "json" || mode === "json0")
            resultText = node.renderer({ content: result.text || ""})
        else
            resultText = (result.text || "").replaceAll("`", "'")
    }
    return (<div key={key} className="md prompt-button m-2 mt-3 d-flex flex-column align-items-stretch">
        <div className="d-flex align-items-center">
            <button className="btn btn-primary d-flex align-items-center position-relative" disabled={running}
                    style={{paddingTop: "2px", paddingBottom: "2px"}}
                    onClick={e =>
                        startQuery(currentPage.value.id, node.attributes.id, node.body, node.attributes.mode,
                            node.attributes.use, node.attributes.query, node.attributes.model, node.attributes.max, e.shiftKey)}>
                <i class="bi bi-play-fill me-1 mt-1" ></i><div className="position-relative" style={{top: "4px"}}>{node.label}{changed(node.attributes.id, "text-info")}</div>
            </button>
            <i className="bi ms-2 mt-1 bi-x-lg text-danger pointer"
               style={{fontSize: "120%", width: "23px", visibility: running ? "visible" : "hidden"}}
               onClick={() => stopQuery(currentPage.value.id, node.attributes.id)}/>
            {running && <div className="spinner-grow spinner-grow-sm text-danger ms-2 mb-1"/>}
        </div>
        {resultText && <pre className="px-2 py-1 ms-1 mt-2 border-start answer-body">
            {resultText}
            {result.date && <div className="muted small mt-1">
                {format(new Date(result.date), "yyyy-M-d H:mm")}
                <span className="ms-3">{result.meta || ""}</span>
            </div>}
        </pre>}
    </div>)
}

export const brickRenderer = (node, cs, key) => {
    // noinspection JSUnusedLocalSymbols
    const _z = updateRunning.value
    if (!node.attributes.id)
        return error("button: missing id")

    // noinspection JSUnusedLocalSymbols
    const running = runningQueryName.value === node.attributes.id && currentPage.value.id === runningQueryPage.value;
    const result = currentPage.value.generated[node.attributes.id]
    const mode = node.attributes.mode;
    let resultText;
    if (result) {
        if (mode === "form") {
            resultText = result.text;
            try {
                resultText = jsonToForm(JSON.parse(resultText));
            }
            catch(e) {
                // invalid json, maybe because of partial generation
            }
            resultText = node.renderer({ content: resultText || ""})
        }
        else if (mode === "markdown" || mode === "json" || mode === "json0")
            resultText = node.renderer({ content: result.text || ""})
        else
            resultText = (result.text || "").replaceAll("`", "'")
    }
    return (<div key={key} className="md prompt-brick m-2 mt-3 d-flex flex-column align-items-stretch">
        <div className="d-flex align-items-center">
            <div className="prompt-brick-header border px-2 pt-2">
                {node.label}{changed(node.attributes.id)}
            </div>
            <i className="bi ms-2 mt-1 bi-x-lg text-danger pointer"
               style={{fontSize: "120%", width: "23px", visibility: running ? "visible" : "hidden"}}
               onClick={() => stopQuery(currentPage.value.id, node.attributes.id)}/>
            {running && <div className="spinner-grow spinner-grow-sm text-danger ms-2 mb-1"/>}
        </div>
        {resultText && <pre className="px-2 py-1 ms-1 mt-2 border-start answer-body">
            {resultText}
            {result.date && <div className="muted small mt-1">
                {format(new Date(result.date), "yyyy-M-d H:mm")}
                <span className="ms-3">{result.meta || ""}</span>
            </div>}
        </pre>}
    </div>)
}

export const systemRenderer = (node, cs, key) => {
    // noinspection JSUnusedLocalSymbols
    return (<div key={key} className="md system-prompt m-2 my-3 d-flex flex-column align-items-stretch">
        <ul className="d-flex align-items-center nav nav-tabs">
            {node.attributes.id && <>
                <li className="nav-item">
                    <div className="nav-link active">{node.attributes.id}</div>
                </li>
                <span className="flex-grow-1"/>
                {node.attributes.use &&
                    node.attributes.use.split(",").map(s => <div className="d-flex align-items-center pointer me-2">
                    <i className="bi bi-arrow-up-right me-2" style={{fontSize: "120%"}}/>{s}
                </div>)}
            </>}
        </ul>
        <div className={classes("p-3 pb-1 border-start border-end prompt-body border-bottom")}>
            {cs}
        </div>
    </div>)
}

export const contextRenderer = (node, cs, key) => {
    extraPageInfo.contexts.push({attributes: node.attributes, body: node.body})
    const disabled = node.attributes.off !== undefined;
    return (<div key={key} className={classes("md m-2 mt-3 d-flex flex-column align-items-stretch", disabled ? "context-disabled" : "context")}>
        {(node.attributes.tag || node.attributes.title) && <div className="d-flex flex-row align-items-center">
            <span className="flex-grow-1"/>
            {node.attributes.id && <div className="title pointer">{node.attributes.id}</div>}
            {node.attributes.tag.split(",").map(tag => <div className="tag pointer ms-2">
                {tag}
            </div>)}
        </div>}
        <div className={classes("p-3 pb-1 context-body", disabled && "muted")}>
            {cs}
        </div>
    </div>)
}

setMessageHandler("run-log", m => {
    // [title, system, context, prompt]
    const [title, system, context, prompt] = m;
    console.group(title)
    console.info(system)
    console.info(context)
    console.info(prompt)
    console.groupEnd()
})